const LOGO_MAPPING = {
	react: "https://cogoport-production.sgp1.digitaloceanspaces.com/60f19d21e99a8e720647ba8a6bb4646a/react.png",
	javascript:
		"https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
	python: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
	html: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
	java: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/java/java.png",
};

export default LOGO_MAPPING;
